const io = require('socket.io-client')

// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
const Config = require('Config');

// const socketUrl = Config.socketUrl;
// const socketPort = Config.socketPort;

const {socketUrl, socketPort} = Config;
const patch = require('socketio-wildcard')(io.Manager);

// <script type="text/javascript" src="/socketcluster.js"></script>
// const socketCluster = require('socketcluster-client')

export default function () {

//   var options = {
//     port: 8000,
//     // path: '/socketcluster/',
//     // hostname: '127.0.0.1',
//     // autoConnect: true,
//     secure: false,
//     // rejectUnauthorized: false,
//     // connectTimeout: 10000, //milliseconds
//     // ackTimeout: 10000, //milliseconds
//     // channelPrefix: null,
//     // disconnectOnUnload: true,
//     // multiplex: true,
//     // autoReconnectOptions: {
//     //   initialDelay: 10000, //milliseconds
//     //   randomness: 10000, //milliseconds
//     //   multiplier: 1.5, //decimal
//     //   maxDelay: 60000 //milliseconds
//     // },
//     // authEngine: null,
//     // codecEngine: null,
//     // subscriptionRetryOptions: {},
//     // query: {
//     //   yourparam: 'hello'
//     // }
//   };

  console.log('Socket connect');
  console.log(`socketUrl: ${socketUrl}, socketPort: ${socketPort}`)
  const socket = io.connect(`${socketUrl}:${socketPort}`)
  //var socket = io.connect(`${socketUrl}:${socketPort}`,{transports: ['websocket'], upgrade: false});

  // This allow listening on all events.
  patch(socket);

  // Initiate the connection to the server
  // var socket = socketCluster.create(options);

  socket.on('connect', function () {
    console.log('CONNECTED!');
  });

  function registerAllHandler(onAll, disconnectHandler, connectHandler) {
    console.log('register all called');
    socket.on('*', onAll);
    socket.on('disconnect', disconnectHandler);
    socket.on('connect', connectHandler);
  }

  function unregisterAllHandler() {
    socket.off('*');
    socket.off('disconnect');
  }

  socket.on('error', function (err) {
    console.log('received socket error:')
    console.log(err)
  })

  function register(name, cb) {
    socket.emit('register', name, cb)
  }

  function login(userInfo/*, cb*/) {
    console.log(userInfo);
    socket.emit('message', { 
                callName: 'loginToSocket',
                user_id: userInfo.user_id,
                access_token: userInfo.access_token,
                auth_provider: userInfo.auth_provider,
                fromWeb: true, 
              }, null);
    // for (let i = 0; i < 10; i++) {
    //   socket.emit('message', {callName: 'sendMessage', chat_id: '43028', text: i.toString()})
    // }
  }

  // function join(chatroomName, cb) {
  //   socket.emit('message', {callName: 'getChatHistory', chat_id: chatroomName.toString()}, cb);
  //   //socket.emit('join', chatroomName, cb)
  // }

  function leave(chatroomName, cb) {
    //socket.emit('leave', chatroomName, cb)
    socket.emit('message', {callName: 'exitChat', chat_id: chatroomName.toString()}, cb)
  }

  function enterChat(chatroomName) {
    socket.emit('message', {callName: 'enterChat', chat_id: chatroomName.toString() }, () => {})
  }

  function message(msg, cb) {
     //socket.emit('message', { callName: 'sendMessage' , chat_id: 184654, text: '&d' }, cb)
    socket.emit('message', msg, cb)
  }

  function typing() {
    socket.emit('message', {callName: 'typing'}, () => {})
  }

  function cancelTyping() {
    socket.emit('message', {callName: 'cancelTyping'}, () => {})
  }

//-------------
  function setProfileType(anon) {
    socket.emit('message', {callName: 'setProfileType', anon }, () => {})
  }

  function changeAnonname(name) {
    socket.emit('message', {callName: 'changeAnonname', name }, () => {})
    //socket.emit('message', {callName: 'changeBio', bio: name }, () => {})
  }

  function hideUserInfo(hide) {
    socket.emit('message', {callName: 'hideUserInfo', hide }, () => {})
  }

//----------------
  // function getChatrooms(user, cb) {
  //   console.log(`socket.getChatrooms user: ${((user && 'name' in user) ? user.name : 'no_user')}`);
  //   const data = {callName: 'getHomeScreen'};
    
  //   socket.emit('message', data, cb);
  //   //socket.emit('message', data, null);
  // }

  return {
    register,
    login,
    //join,
    leave,
    message,
    //getChatrooms,
    enterChat,
    registerAllHandler,
    unregisterAllHandler,
    typing,
    cancelTyping,
    setProfileType,
    changeAnonname,
    hideUserInfo
  }
}
