/* eslint-disable */

export function reducer(state = {}, action) {

    console.log('reducer called:');
    console.log(action);
    console.log(state);
    
    //Consider saving each chat history under it's own section.
    //   in such case, mapStateToProps in Chatroom should map only the relevant chat
    //   and will not be affected buy updates of different chats
    //other user image should also be set per user.
    switch (action.type) {
        case 'CHAT_HISTORY':
            return {
                ...state,
                chatHistory: action.chatHistory,
                currentChatId: action.currentChatId,
            };

        case 'SET_CURRENT_USER':
            return {
                ...state,
                currentUser: action.currentUser,
            };

        case 'NEW_MESSAGE':
            return {
                ...state,
                newMessage: action.newMessage,
                currentChatId: action.currentChatId,
                lastMessageId: action.newMessage.message_id,
            };

        case 'CHAT_FILTER':
            return {
                ...state,
                chatFilter: action.chatFilter,
                lastFilterTime: action.lastFilterTime,
            };
        case 'REPORT_STATUS':
            return {
                ...state,
                reportStatus: action.status,
            }
        default:
            return state;
    }
}