import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const {generateTimeLabel} = require('../both/Utils')()
const axios = require('axios');
const Config = require('Config');
 
const styles = {
  
    tooltip: {
      color: '#ffffff',
      backgroundColor: '#ffffff',
      rippleBackgroundColor: '#000000',
    },
    textField: {
          width: 200,
          color: '#fafafa',
        },
        
    };


class UserProfile extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            anonName: '',
            questionStatus: '',
        }
      
        this.setAnon = this.setAnon.bind(this);
        this.setAnonName = this.setAnonName.bind(this);
        this.changeAnonName = this.changeAnonName.bind(this);
        this.hideUserInfo = this.hideUserInfo.bind(this);
      // this.setProfileType = this.setProfileType.bind(this);
    }


    setAnon(event) {

      const result = window.confirm('Are you sure you want to change Profile Type ?');

      if (!result) 
        return;
 
     // this.setState({ anon: event.target.value });
      this.props.onSetProfileType(event.target.value == 'true' ? 1 : 0);

    }

    setAnonName(event) {

      this.setState({ anonName: event.target.value });
    }

    changeAnonName() {

      this.props.onChangeAnonname(this.state.anonName);
    }

    hideUserInfo(event) {

      this.props.onhideUserInfo(event.target.value);
    }

  render() {

    const { classes } = this.props;

    return (    
      <div style={{ maxWidth: 250, marginBottom: 40}}>
        <Card style={{ backgroundColor: '#E7E6E6'}}>
          <CardContent>
            {
              <div>
                <div className="pr-16" >Choose Profile Type</div>

                <RadioGroup
                  aria-label="Anon"
                  name="Anon"
                  //className={classes.group}
                  value={this.props.anon ? (this.props.anon == 'true' ? 'true' : 'false') : 'false'}
                  onChange={this.setAnon}
                >
                  <FormControlLabel value="false" control={<Radio />} label="regular" />
                  <FormControlLabel value="true" control={<Radio />} label="anon" />
                </RadioGroup>
                <Divider light={false} />

                <div className="pr-16" >Choose privacy mode</div>
                <div className="pr-16" >
                  <div style={{ maxWidth: 300, marginBottom: 20}} >     
                    <Select 
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={this.props.hide}
                      // value={0}
                      onChange={this.hideUserInfo}
                    >
                      <MenuItem value={0}>Show All </MenuItem>
                      <MenuItem value={1}>Hide Age</MenuItem>
                      <MenuItem value={2}>Hide Location</MenuItem>
                      <MenuItem value={3}>Hide Both </MenuItem>
                    </Select>
                  </div>
                </div>

                <Divider light={false} />

                <div className="pr-16" >Enter New Anon Name</div>
                <div className="pr-16" >
                  <div style={{ maxWidth: 300, marginBottom: 20}} >
                    <TextField
                      id="standard-uncontrolled"
                      //disabled={this.state.anonName}
                      label="anon name"
                      name="notes"
                      multiline
                      rows="2"
                      value={this.state.anonName}
                      className={classes.textField}
                      type="text"
                      onChange={this.setAnonName}
                      
                    />
                  </div>    
                </div>

                <div>
                  <Button 
                    variant="contained"
                    color="secondary"
                    onClick={this.changeAnonName}
                  >
                        change Anon Name
                  </Button>

                  <TextField
                    id="standard-read-only-input"
                    label="Status"
                    name="status"
                    value={this.props.anonStatus}
                    className={classes.textField}
                    multiline
                    rows="2"    
                  />
                </div>          
              </div>
            }
          </CardContent>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        reportStatus: state.reportStatus,

    }
}

export default connect(mapStateToProps)(withStyles(styles)(UserProfile));
