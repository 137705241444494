import React from 'react';
import styled from 'styled-components'
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
//import Paper from '@material-ui/core/Paper';
//import IconButton from '@material-ui/core/IconButton';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
//import CardMedia from '@material-ui/core/CardMedia';

const Wrapper = styled.div`
  cursor: pointer;
`

const getCardTitleStyle = () => ({
  display: 'flex',
  alignItems: 'center',
})

function userAvatar(chatroom) {
  return (
    <Avatar
      aria-label="Recipe" 
      title={chatroom.other_user_info_array 
      ? chatroom.other_user_info_array.fname 
      : 'No first name'}
      src={chatroom.other_user_info_array && chatroom.other_user_info_array.profile_image ? chatroom.other_user_info_array.profile_image : ''
          }
    >
      {chatroom.other_user_info_array && chatroom.other_user_info_array.profile_image 
      ? null 
      : chatroom.other_user_info_array && chatroom.other_user_info_array.fname
        ? chatroom.other_user_info_array.fname.substring(0, 2) 
        : 'X'}
    </Avatar>
  );
}

function applyAvatarBadge(chatroom) {
    switch (chatroom.status) {
    case 1: // normal - no unseen messages
      return (userAvatar(chatroom));
    case 2: // has unseen messages
      //"default"=white,"primary"=blue,"secondary"-purpule(ish),"error"-red
      return (<Badge color="primary" badgeContent={chatroom.unread_messages ? chatroom.unread_messages : ''}>{userAvatar(chatroom)}</Badge>)
    case 3: //incomming question that was not seen
      return (<Badge color="error" badgeContent="?">{userAvatar(chatroom)}</Badge>);
    case 4: //outgoing question that was not answered
      return (<Badge color="secondary" badgeContent={chatroom.unread_messages ? chatroom.unread_messages : -1}>{userAvatar(chatroom)}</Badge>);
    default:
      return (userAvatar(chatroom));
  }
}

export default ({ chatroom, onEnter }) => (
  // <Paper zDepth={4}
  //   style={{ maxWidth: 600, marginBottom: 40}}
  //   elevation={5}
  //   //zDepth={5}
  // >
  <div style={{ maxWidth: 500, marginBottom: 40}}>
    <Wrapper onClick={chatroom.chat_id ? onEnter : null}>
      <Card style={{ background: 'rgba(0,0,0,.7)'}}>
        <CardHeader
          avatar={applyAvatarBadge(chatroom)}
          title={<Typography style={{color: '#ffffff', display: 'inline-block'}}> {chatroom.question} </Typography>}
          //title={chatroom.question}
          subheader={<Typography style={{color: '#909090', display: 'inline-block'}}> {chatroom.time_header} </Typography>}
          //subheader={chatroom.time_header}
          style={getCardTitleStyle()}
        />
        <CardContent>
          {
            <Typography style={{color: '#ffffff', display: 'inline-block'}}>
              {chatroom.last_message ? chatroom.last_message : 'No Last Message'}
            </Typography>
              //chatroom.last_message ? chatroom.last_message : "No Last Message"
            }
        </CardContent>
      </Card>
    </Wrapper>
  </div>
  // </Paper>
)
