
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
//import FontIcon from 'material-ui/FontIcon';
import Icon from '@material-ui/core/Icon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import FullScreen from './FullScreen';
import Overlay from './Overlay';
import SelectionMenu from './SelectionMenu';
import AskQuestion from './AskQuestion';
import UserProfile from './UserProfile';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

  // eslint-disable-next-line no-unused-expressions
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1;
`
const Center = styled.div`
  position: relative;
  max-width: 1000px;
  margin: auto;
  padding: 40px 0;
  height: 100%;
  box-sizing: border-box;
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 20px;
  height: 100%;
`

const BackgroundImage = styled.div`
  background: url(${props => props.src}) no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  overflow: hidden;
`

const AvatarWrapper = styled.div`
cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
  }
  img {
    box-shadow: rgba(255, 255, 255, 0.2) 0 0 10px 2px;
  }
`

const Relative = styled.div`
  position: relative;
`

const Sticky = styled.div`
  position: fixed;
`
const UserName = styled.p`
  font-size: 24px;
  height: 27px;
  text-align: center;
  color: #fafafa;
`
function renderAvatar(user, classes) { 
  const ret = user
    ? 
      <Avatar 
        style={{border: 0, objectFit: 'cover', height: 160, width: 160}} 
        src={user.profile_image} 
        // classes={{classes}}
        />
    : 
      (<Avatar
        style={{
          border: 0, objectFit: 'cover', height: 160, width: 160
          }}
        // classes={{classes}}
      >
        <Icon style={{ fontSize: 96 }} className="material-icons">
          perm_identity
        </Icon>
       </Avatar>
       )
    
  return ret;
  //return <Avatar size={160} {...props} />
}

function fullName(user) {
  return user ? `${user.name}` : 'Click for login';
}

export default ({ 
  children, user, info, updateSentQuestion, onSetProfileType, onChangeAnonname, onhideUserInfo, anonStatus 
}) => {
  
  const classes = useStyles();

  return (
    <div>
    <FullScreen>
      <ContentWrapper>
        <Center>
          <Content>
            <Relative>
              <Sticky>
                <AvatarWrapper>
                  <Link to="/user">
                    { renderAvatar(user, classes) }
                  </Link>
                  {/* <UserName> { fullName(user) } </UserName> */}
                  <Accordion 
                    style={{backgroundColor: '#151515'}} 
                    // classes={{classes}}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography style={{color: '#FFFFFF'}}>Profile  { fullName(user) }</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <UserProfile 
                        anon={user ? (user.is_anon ? (user.is_anon == false ? 'false' : 'true') : 'false') : 'false'} 
                        onChangeAnonname={onChangeAnonname} 
                        // hide is not returned from new_user - should be using another api instead.
                        // hide={user ? user.hide : 0}
                        onSetProfileType={onSetProfileType}
                        anonStatus={anonStatus}
                        onhideUserInfo={onhideUserInfo}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <SelectionMenu />
                  <Accordion 
                    style={{backgroundColor: '#151515'}} 
                    // classes={{classes}}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography style={{color: '#FFFFFF'}}>Click to send a Question</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AskQuestion info={info} updateSentQuestion={updateSentQuestion} /> 
                    </AccordionDetails>
                  </Accordion>
                </AvatarWrapper>
              </Sticky>
            </Relative>
            { children }
          </Content>
        </Center>
      </ContentWrapper>
      <FullScreen>
        <BackgroundImage src="/background.png" />
        <Overlay
          opacity={0.4}
          background="#212121"
        />
      </FullScreen>
    </FullScreen>
    </div>
  )
}

// export default withStyles({})(MainLayout);
// export default MainLayout;
