module.exports = function () {

    const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    function timeFormat(time) {
        const ddate = new Date(time * 1000);
        //const date = ddate.getDate();
        const month = monthNames[ddate.getMonth()];

        return `${ddate.getDate()}-${month}-${ddate.getFullYear()}`;
    }

    function generateTimeLabel(time) {
        const dayNum = Math.floor(time / 86400);
        // let todayNum = Math.floor((new Date().now()/1000)/86400);
        const todayNum = Math.floor((Date.now() / 1000) / 86400);

        if (dayNum == todayNum)
            return 'Today';
        
        if (dayNum == (todayNum - 1))
            return 'Yesterday';
        
        if (dayNum > (todayNum - 6))
            return weekday[(new Date(time * 1000)).getDay()];
        
        return timeFormat(time);
    }
    return {
        generateTimeLabel,
     
    }
}
