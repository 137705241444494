import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { reducer } from './reducer';
import { BrowserRouter } from 'react-router-dom';

import Root from './Root';

const store = createStore(reducer);
console.log('redux store created');

ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
    <Root />
  </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept('./Root', () => {
    // eslint-disable-next-line
    const NextRoot = require('./Root').default
    ReactDOM.render(
      <Provider store={store}>
        <NextRoot />
      </Provider>,
      document.getElementById('root')
    )
  })
}
