import React from 'react';
import { connect } from 'react-redux';
import ChatroomPreview from './ChatroomPreview'
// import Report from './Report'


class Home extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div style={{zIndex: 10}}>
          {
        
        this.props.chatrooms ?
        this.props.chatrooms.map(chatroom => (
          (!this.props.chatFilter || this.props.chatFilter(chatroom)) ? (
            <ChatroomPreview 
              //Not all chatrooms have chat_id, some only have question_id (the ones with no answers).
              //  in such case there will only one with this question id.
              key={chatroom.chat_id ? `${chatroom.chat_id}_0` : `${chatroom.question_id}__0`}
              chatroom={chatroom}
              onEnter={() => this.props.onEnterChatroom(chatroom.chat_id)}
            />
          ) : ''
          ))
          : ''
        }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    chatFilter: state.chatFilter,
    lastFilterTime: state.lastFilterTime,
  };
}

export default connect(mapStateToProps)(Home);
