import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const axios = require('axios');
const Config = require('Config');

const styles = {
    tooltip: {
      color: '#ffffff',
      backgroundColor: '#ffffff',
      rippleBackgroundColor: '#000000',
    },
    textField: {
          width: 200,
          color: '#fafafa',
        },
    };


class Report extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            toBlock: false,
            disableBlock: false,
            disableType: false,
            reportType: '-1',
            textReason: '',
            textDisabled: true,
        }

        const {restPath} = Config;

        this.handleBlockCheck = this.handleBlockCheck.bind(this);
        this.setReportType = this.setReportType.bind(this);
        this.setTextReason = this.setTextReason.bind(this);
        this.reportUser = this.reportUser.bind(this);
        //this.props.sendMessage = this.props.sendMessage.bind(this);

        const params = {
            user_id: this.props.userId,
            other_user_id : this.props.otherUserId,
            access_token: this.props.accessToken,
            auth_provider: this.props.authProvider,
            chat_id: this.props.chat_id
        }
  
      axios.post(`${restPath}/pxy/get_report_data`, params)
      .then((res)=>{

          if (res.data.response.blockData) {
            this.setState({toBlock : false, disableBlock: true, disableType: true, reportType: '0' });   

          } else if (res.data.response.reportData) {
            this.setState({toBlock : true, disableBlock: true, disableType: true, reportType: '0' });   
          }
      }).catch((err)=>{
          console.error(`Error in get_report_data : ${err.message}`);
      })
    }
   

    setReportType(event) {
      
      if (event.target.value == 0) {
          return;
      }

      this.setState({ textReason: ''});

      const val = event.target.value
      this.setState({ reportType: Number(val) });
      if (event.target.value != '6') {
          this.setState({ textReason: event.target[Number(val)+1].label});
          this.setState({ textDisabled: true});
      } else
          this.setState({ textDisabled: false});   
  
      this.props.dispatch({
                type: 'REPORT_STATUS', 
                status: false, 
              });    
    }

    setTextReason(event) {
        this.setState({ textReason: event.target.value });
    }

    handleBlockCheck(event) {
        this.setState({ toBlock: event.target.checked == true ? true : false});
    }

    reportUser() {

    if (this.state.reportType == '-1') {
        window.alert('Please choose Report type');
        return;
    }

    if (this.state.reportType == '6' && this.state.textReason == '') {
        window.alert('please fill report reason in text box');
        return;
    }

    this.props.sendMessage({ 

        type: this.state.reportType,
        user_id: this.props.otherUserId.toString(),
        callName: 'reportUser',
        text: this.state.textReason,
        is_block: this.state.toBlock,
        unblock: this.state.toBlock == false && this.state.reportType == 0 ? true : false,
        chat_id: this.props.chat_id
    }
        , (err) => {
            if (err)
                return console.error(err);
            return undefined;    
           })

        // =====================
        if (this.state.toBlock) { // any kind of block ,allow only unblock after
          this.setState({toBlock : false, disableBlock: true, disableType: true, reportType: '0' });
          return;
        }
        if (this.state.reportType != 0) { // regular report with no block - allow only block after
          this.setState({toBlock : true, disableBlock: true, disableType: true, reportType: '0' });   
          return;
        }
        // default config
        this.setState({toBlock : false, disableBlock: false, disableType: false, reportType: '-1' });
        return;
    }

    render() {
     
    const { classes } = this.props;
    
    return (    

      // check rest call and if block allow unblock only ?

      <div style={{ maxWidth: 600, marginBottom: 40}}>
        <Card style={{ backgroundColor: '#E7E6E6'}}>
          <CardHeader
            title={<Typography style={{color: '#FF0000'}}>Report User Form</Typography>}
          />
          <CardContent>
            {
              <div>
                <div className="pr-16" >
                  <div>Please choose Report Type</div>
                  <Select
                    native
                    //defaultValue={0}
                    value={this.state.reportType}
                    onChange={this.setReportType} 
                    disabled={this.state.disableType}
                    inputProps={{
                    name: 'reportPicker',
                    id: 'age-native-simple',
                    }}
                  >
                    <option value={-1}>None</option>
                    <option value={0}>Block/Unblock only - No report</option>
                    <option value={1}>Offensive/ Abusive/ Inappropriate</option>
                    <option value={2}>Not trustworthy</option>
                    <option value={3}>Fraudulent</option>
                    <option value={4}>Spammer</option>
                    <option value={5}>Not a question</option>
                    <option value={6}>Other</option>
                     
                  </Select>
              
                  <div style={{ maxWidth: 300, marginBottom: 40}} >
                    <TextField
                      id="standard-multiline-flexible"
                      disabled={this.state.textDisabled}
                      label="Enter report reason"
                      name="notes"
                      multiline
                      rows="2"
                      value={this.state.textReason}
                      className={classes.textField}
                      type="text"
                      onChange={this.setTextReason}
                    />
                  </div>    

                  <div >Check to block user</div>

                  <Checkbox 
                    checked={this.state.toBlock}
                    disabled={this.state.disableBlock}
                    onChange={this.handleBlockCheck}
                    value="toBlock"
                  />
                </div>

                <div>
                  <Button 
                    variant="contained"
                    color="secondary"
                    onClick={this.reportUser}
                  >
                    { this.state.toBlock == false && this.state.reportType == 0 ? 'Unblock User' :
                      (this.state.toBlock == true && this.state.reportType == 0 ? 'Block User' : 'Report User')}
                  </Button>

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <TextField
                    id="standard-read-only-input"
                    label="Status"
                    name="status"
                    //value={this.state.textReason}
                    className={classes.textField}         
                    value={this.props.reportStatus == true ? 'Report Succesfull' : ''}
                  />
                </div>          
              </div>
            }
          </CardContent>
        </Card>
      </div>
 );
}
}

function mapStateToProps(state) {
    return {
        reportStatus: state.reportStatus,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Report));
