import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// replace with this package: https://www.npmjs.com/package/material-ui-phone-number
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'
import Typography from '@material-ui/core/Typography';

// const {generateTimeLabel} = require('../both/Utils')()
const axios = require('axios');
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved

const Config = require('Config');

const {restPath, reCAPTCHAKey} = Config;
 
const styles = {
    tooltip: {
      color: '#ffffff',
      backgroundColor: '#ffffff',
      rippleBackgroundColor: '#000000',
    },
    textField: {
          width: 200,
          color: '#fafafa',
        },
    };

class MobileLogin extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            phoneNumber: '+',
            sendCodeStatus: '',
            verifyCodeStatus: '',
            code: '',
            lastSentPhone: '',
            firstName: '',
            lastName: '',
            email: '',
        }

        this.callback = props.callback;
      
        this.setPhoneNumber = this.setPhoneNumber.bind(this);
        this.setCode = this.setCode.bind(this);
        this.setFirst = this.setFirst.bind(this);
        this.setLast = this.setLast.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.sendCodeToUser = this.sendCodeToUser.bind(this);
        this.loginWithCode = this.loginWithCode.bind(this);
    }

    componentDidMount() {
      // Define the callback function to initialize grecaptcha
      window.recaptchaLoaded = () => {
        this.grecaptcha = window.grecaptcha;
      };

      // Dynamically load the reCAPTCHA script
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${reCAPTCHAKey}&onload=recaptchaLoaded`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }

    setPhoneNumber(event) {
      // replace with this package: https://www.npmjs.com/package/material-ui-phone-number
      this.setState({phoneNumber: new AsYouType().input(event.target.value.startsWith('+') ? event.target.value : `+${event.target.value}`)});
    }

    setCode(event) {
      this.setState({ code: event.target.value });
    }

    setFirst(event) {
      this.setState({ firstName: event.target.value });
    }

    setLast(event) {
      this.setState({ lastName: event.target.value });
    }

    setEmail(event) {
      this.setState({ email: event.target.value });
    }

  sendCodeToUser() {
    if (!this.grecaptcha) {
      console.error('reCAPTCHA not loaded');
      return;
    }

    this.setState({
      sendCodeStatus: '',
      verifyCodeStatus: '',
      lastSentPhone: '',
    });

    const phoneNumber = parsePhoneNumberFromString(this.state.phoneNumber);

    // console.log(JSON.stringify(phoneNumber));
    // replace with this package: https://www.npmjs.com/package/material-ui-phone-number
    if (!phoneNumber || !phoneNumber.isValid()) {
      this.setState({sendCodeStatus: 'Please enter a valid phone number'});
      return;
    }

    console.log(JSON.stringify(phoneNumber));

    let params = {
      phone_number: phoneNumber.number,
    }

    const setStateFunction = this.setState.bind(this);
    this.grecaptcha.ready(function () {
      this.grecaptcha.execute(reCAPTCHAKey, {action: 'submit'})
      .then(function (token) {
        params.token = token;
        return axios.post(`${restPath}/pxy/send_user_code`, params)
      })
      .then((data) => {

        setStateFunction({
          sendCodeStatus: data.data.status ? 'Code sent, please enter received code below' 
                        : `Error: ${data.data.error}`,
          phoneNumber: '+',
        });
  
        if (data.data.status) {
          setStateFunction({lastSentPhone: phoneNumber.number});
        }
  
      }).catch((err) => {
           console.error('Error in send code : ');
           console.error(err.message);
           setStateFunction({ sendCodeStatus: 'Fail to send code to user'});
      })
    });
    // axios.post(`${restPath}/pxy/send_user_code`, params)
    // .then((data) => {

    //   this.setState({
    //     sendCodeStatus: data.data.status ? 'Code sent, please enter received code below' :
    //                   `Error: ${data.data.error}`,
    //     phoneNumber: '+',
    //   });

    //   if (data.data.status) {
    //     this.setState({lastSentPhone: phoneNumber.number});
    //   }

    // }).catch((err) => {
    //      console.error('Error in send code : ');
    //      console.error(err.message);
    //      this.setState({ sendCodeStatus: 'Fail to send code to user'});
    // })
  }

  //Consider replacing this with new_user call
  loginWithCode() {

    if (this.state.lastSentPhone == '') {
      this.setState({verifyCodeStatus: 'Code was not sent to user yet'});
      return;
    }

    this.setState({verifyCodeStatus: ''});

    const userCode = this.state.code;

    if (!userCode || userCode.length != 6) {
      this.setState({verifyCodeStatus: 'Invalid Code'});
      return;
    }

    console.log(JSON.stringify(userCode));

    const response = {
      userID: this.state.lastSentPhone,
      code: userCode,
    }

    if (this.state.email != '') 
      response.email = this.state.email;

    if (this.state.firstName != '') 
      response.firstName = this.state.firstName;

    if (this.state.lastName != '') 
      response.lastName = this.state.lastName;

    this.callback(response);
  }

  render() {
     
    const { classes } = this.props;
    
    return (  
        
      <div style={{ maxWidth: 250, marginBottom: 40}}>
        <Card style={{ backgroundColor: '#E7E6E6'}}>
          <CardContent>
            <div>
              <div className="pr-16" >
                <div style={{ maxWidth: 300}} >
                  <TextField
                    id="standard-name"
                    disabled={this.state.textDisabled}
                    label="Please enter phone number"
                    name="notes"
                    value={this.state.phoneNumber}
                    className={classes.textField}
                    type="text"
                    onChange={this.setPhoneNumber}
                  />
                  <Typography style={{ color: '#ff0000' }}> {this.state.sendCodeStatus} </Typography>                  
                </div>    
              
                <div style={{ height: '5px' }} />
                <Button 
                  variant="contained"
                  color="secondary"
                  onClick={this.sendCodeToUser}
                >
                  Send Code by SMS
                </Button>
                <div style={{ height: '5px' }} />
                <Button 
                  variant="contained"
                  color="secondary"
                  onClick={this.sendCodeToUser}
                >
                  Send code by Voice call
                </Button>
              </div>

              <div className="pr-16">

                <TextField
                  id="standard-first"
                  label="Please enter first name"
                  name="firstName"
                  value={this.state.firstName}
                  className={classes.textField}
                  type="text"
                  onChange={this.setFirst}
                />
                <TextField
                  id="standard-last"
                  label="Please enter last name"
                  name="lastName"
                  value={this.state.lastName}
                  className={classes.textField}
                  type="text"
                  onChange={this.setLast}
                />
                <TextField
                  id="standard-email"
                  label="Please enter email"
                  name="email"
                  value={this.state.email}
                  className={classes.textField}
                  type="text"
                  onChange={this.setEmail}
                />
              </div>

              <div className="pr-16" >
                <div style={{ maxWidth: 300, marginBottom: 40}} >
                  <TextField
                    id="standard-name"
                    label="Please enter code"
                    name="code"
                    value={this.state.code}
                    className={classes.textField}
                    type="text"
                    onChange={this.setCode}
                  />
                  <Typography style={{ color: '#ff0000' }}> {this.state.verifyCodeStatus} </Typography>                  

                  <div style={{ height: '5px' }} />
                  <Button 
                    variant="contained"
                    color="secondary"
                    onClick={this.loginWithCode}
                  >
                    Login with code
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        reportStatus: state.reportStatus,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MobileLogin));
