import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorBoundary from './ErrorBoundary';
import { withStyles } from '@material-ui/core/styles';
import Overlay from './Overlay';
import green from '@material-ui/core/colors/green';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
const reactStringReplace = require('react-string-replace')
import {getQuestionsHeader, getButtonHeader, ChatWindow, Header, ChatPanel, Title, NoDots, InputPanel, ChatroomImage, Scrollable} from './ChatroomDivs'
import Fab from '@material-ui/core/Fab';
import {SendTwoTone, ThumbUpTwoTone, ImageTwoTone} from '@material-ui/icons/';

const styles = {
  tooltip: {
    color: '#ffffff',
    backgroundColor: '#ffffff',
    rippleBackgroundColor: '#000000',
  },
  textField: {
        width: 200,
        color: '#fafafa',
      },
  };

//Attempt to vertically center the uploaded image preview - didn't work
// It pushes small images to the left
const VCenter1 = styled.div`
  position: absolute;
  top: 50%; left: 0%;
  transform: translate(0%,-50%);
`

const VCenter2 = styled.div`
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
`

class UploadImage extends React.Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      file: '',
      imagePreviewUrl: ''
    };
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);

    this.onSendMessage = this.onSendMessage.bind(this)
  }

  componentDidMount() {
    // this.fileInput.click();
    const element = document.getElementById("fileInputID");
    if (element)
      element.click();

  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }


  _handleSubmit(e) {
    e.preventDefault();

    //console.log(`file:  ${}`)
    // TODO: do something with -> this.state.file
    console.log(this.state.imagePreviewUrl.substring(this.state.imagePreviewUrl.indexOf(',')+1));

    console.log("onSendMessage");
    if (!this.state.imagePreviewUrl)
        return

    // this.props.onSendMessage(this.state.input, (err) => {
    this.props.onSendMessage({  callName : "sendMessage" , 
                                chat_id:this.props.chatroom.chat_id.toString(), 
                                image: this.state.imagePreviewUrl.substring(this.state.imagePreviewUrl.indexOf(',')+1) }
                                , 
                                (err) => {
                                    if (err){
                                      return console.error(err)
                                    }
                                  //The component is exiting - no need to set state.
                                  //return this.setState({ imagePreviewUrl: '' })
                                });

    this.props.onLeave();
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
    this.setState({
      file: file,
      imagePreviewUrl: reader.result
    });
  }

  reader.readAsDataURL(file)
}



  onSendMessage() {
    console.log("onSendMessage");
    if (!this.state.input)
      return

      // this.props.onSendMessage(this.state.input, (err) => {
      this.props.onSendMessage({ callName : "sendMessage" , chat_id:this.props.chatroom.chat_id.toString(), text: this.state.input }, (err) => {
        if (err)
      return console.error(err)

      return this.setState({ input: '' })
    })
  }

  render() {
    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<img src={imagePreviewUrl} width='100%'/>);
    }

    const { classes } = this.props;

    return (
      <ErrorBoundary>
      <div style={{ height: '100%' }}>
        {getButtonHeader(this.props, classes.button)}
        {getQuestionsHeader(this.props)}
        <ChatWindow>
        <ChatPanel>
          <List>
          <ListItem>
          {$imagePreview}
          </ListItem>
          </List>
          <Divider key={`divider_1`} />
          <div align='right'>
              <Tooltip id="tooltip-send" title="Select image" placement="top">

                <Fab 
                  size="small" 
                  color="primary" 
                  aria-label="upload" 
                  className={classes.margin}
                >
                  <ImageTwoTone/>
                  <input type="file" id="fileInputID"
                  // ref={fileInput => this.fileInput = fileInput}
                  style= {{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    right: '0',
                    left: '0',
                    width: '100%',
                    opacity: '0'
                    }} onChange={this._handleImageChange} 
                  />
                  
                  {/* Send */}                    
              </Fab>

              {/* <Button
                  variant="contained"
                  onClick={this.onSendMessage}
                  color="primary"
                  className={classes.button}
                  style={{ width: 20 }}
                  endIcon={<Icon>add_a_photo</Icon>}
                >
                <input type="file" 
                ref={fileInput => this.fileInput = fileInput}
                style= {{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  right: '0',
                  left: '0',
                  width: '100%',
                  opacity: '0'
                  }} onChange={this._handleImageChange} />
                  {Send}
              </Button> */}
              </Tooltip>
              <Tooltip id="tooltip-send" title="Send Message" placement="top">
                <Fab 
                  size="small" 
                  color="primary" 
                  aria-label="send" 
                  className={classes.margin}
                  onClick={this._handleSubmit}
                >
                  <SendTwoTone />
                </Fab>
              </Tooltip>

              {/* <Tooltip id="tooltip-upload" title="Send image" placement="top" className={classes.tooltip}>
              <Button
                  variant="contained"
                  onClick={this._handleSubmit}
                  color="primary"
                  className={classes.button}
                  style={{ width: 20 }}
                  endIcon={<Icon>send</Icon>}
                >
                  {Send}
              </Button>              
              </Tooltip> */}
              </div>
          </ChatPanel>
          <Overlay
            opacity={0.6}
            background="#111111"
          />

        </ChatWindow>
      </div>
      </ErrorBoundary>
    )
  }
}

//In case where every chat history will be saved under different section,
//  the function below should take only the section for the relevant chat.
function mapStateToProps(state, ownProps) {
  console.log('Chatroom mapStateToProps:' );
  console.log(state);
  console.log(ownProps)

  if (state.newMessage && state.currentChatId == ownProps.chatroom.chat_id)
  {
    if (state.chatHistory)
      state.chatHistory.push(state.newMessage);
  }

  state.newMessage = null;

  return {
    currentChatId: state.currentChatId,
    currentUser: state.currentUser,
  };
}

export default withStyles(styles)(UploadImage);
