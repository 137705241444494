import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import injectTapEventPlugin from 'react-tap-event-plugin';
//import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import ErrorBoundary from './ErrorBoundary';
import MainLayout from './MainLayout';
import Home from './Home';
import Loader from './Loader';
import UserSelection from './UserSelection';
import Chatroom from './Chatroom';
import socket from './socket';
import UploadImage from './UploadImage';
//import { resolve } from 'url';
const _ = require('lodash');

const theme = createMuiTheme({
  /* theme for v1.x */
});
const axios = require('axios');
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
const Config = require('Config');

// injectTapEventPlugin()

class Root extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      user: null,
      isRegisterInProcess: false,
      client: socket(),
      chatrooms: null,
      currentChatId: '',
      anonStatus: '',
    }

    this.onEnterChatroom = this.onEnterChatroom.bind(this)
    this.onLeaveChatroom = this.onLeaveChatroom.bind(this)
    this.getChatrooms = this.getChatrooms.bind(this)
    this.register = this.register.bind(this)
    this.registerUser = this.registerUser.bind(this)
    this.renderUserSelectionOrRedirect = this.renderUserSelectionOrRedirect.bind(this)
    this.getMissingChat = this.getMissingChat.bind(this);
    this.callNameEventHandler = this.callNameEventHandler.bind(this);
    this.disconnectEvnetHandler = this.disconnectEvnetHandler.bind(this);
    this.connectEventHandler = this.connectEventHandler.bind(this);
    this.updateChatrooms = this.updateChatrooms.bind(this);
    this.handleFirstAnswerAndNewQ = this.handleFirstAnswerAndNewQ.bind(this);
    this.updateSuperlike = this.updateSuperlike.bind(this);
    this.updateSentQuestion = this.updateSentQuestion.bind(this);
    this.onSetProfileType = this.onSetProfileType.bind(this);
    this.onChaneAnonname = this.onChaneAnonname.bind(this);
    this.onhideUserInfo = this.onhideUserInfo.bind(this);
    //this.handleNewQuestion = this.handleNewQuestion.bind(this);
    this.userInfo = {};

    //this.getChatrooms()
  }

  // onMessageReceived(entry) {
  //   console.log('Root onMessageReceived:', entry)
  //   this.updateChatHistory(entry)
  // }

  componentDidMount() {
    console.log('Root: ' + this.props.location.search)
    //this.state.client.registerHandler(this.onMessageReceived);
    //window.addEventListener("scroll", this.onScroll.bind(this), false);
    this.state.client.registerAllHandler(this.callNameEventHandler, this.disconnectEvnetHandler, this.connectEventHandler);
    console.log('Root mounted');
    // this.context.history.pushState({}, `/user`);
    this.props.history.push(`/user${this.props.location.search}`)
  }

  componentWillUnmount() {
    // this.state.client.unregisterHandler();
    this.state.client.unregisterAllHandler();
    //window.removeEventListener("scroll", this.onScroll.bind(this), false);
    console.log('Root unmounted');
  }

  
// onScroll() {
//   const nearBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 400;
//   //if (!this.props.fetching && nearBottom) {
//   if (nearBottom) {  
//         this.fetchItems();  
//   }
// }


// fetchItems() {
//   this.setState({page: this.state.page + 1});
//   console.log(`page number: ${this.state.page}`);
// }


  onEnterChatroom(chatroomName, onNoUserSelected, onEnterSuccess) {
    if (!this.state.user)
      onNoUserSelected()

    // return this.state.client.join(chatroomName, (err, chatHistory) => {
    //   if (err) {
    //     console.log('getChatHistory returned error:');
    //     return console.error(err);
    //   }

    //   console.log('getChatHistory Callback:');
    //   console.log(chatHistory);

    //   //Chat history will be received via socket=>redux, send empty object.
    //   return onEnterSuccess({});
    // })
    //================================================
    this.state.client.enterChat(chatroomName)

      this.setState({currentChatId: chatroomName});
      
      const {user_id} = this.userInfo;
      const {restPath} = Config;
      const {auth_provider} = this.userInfo;
      const params = {
          user_id,
          access_token: this.userInfo.access_token,
          auth_provider,
          chat_id: chatroomName,
          last_seen: 0
      }

     axios.post(`${restPath}/pxy/get_last_messages`, params)

     .then((data) => {

      if (data.data.status == false) {
        const err = {};
        err.message = data.data.error;
        throw err;
    }

      const chatMessages = data.data.response[0].messages.sort(function (a, b) {
        return a.time - b.time
      });

      this.props.dispatch({type: 'CHAT_HISTORY', chatHistory: chatMessages, currentChatId: data.data.response[0].chat_id});

      return onEnterSuccess({});

     }).catch((err) => {
        console.error('Error get_last_messages : ');
        console.error(err.message);
     })

  }

  onSetProfileType(anon) {

    this.setState({anonStatus: ''});

    this.state.client.setProfileType(anon);

  }

  onChaneAnonname(name) {

    this.setState({anonStatus: ''});

    this.state.client.changeAnonname(name);

  }

  onhideUserInfo(hide) {

    this.state.client.hideUserInfo(hide);

  }

  onLeaveChatroom(chatroomName, onLeaveSuccess) {
    this.setState({currentChatId: ''});

    const index = this.state.chatrooms.findIndex(function (element) {
      return element.chat_id == chatroomName;
    });

    if (index == -1) {
      console.log('onLeaveChatroom: could not find chat');
    } else {

      const newChatrooms = this.state.chatrooms;
      newChatrooms[index].unread_messages = 0;
      newChatrooms[index].status = 1;
      this.setState({chatrooms: newChatrooms})  
    }
    

    this.state.client.leave(chatroomName, (err) => {
      if (err)
        return console.error(err)
      return onLeaveSuccess()
    })

    //this.getChatrooms();
  }

  getChatrooms() {

      const {user_id} = this.userInfo;
      const {restPath} = Config;
      const params = {
          user_id,
          access_token: this.userInfo.access_token,
          auth_provider: this.userInfo.auth_provider,
          app_version: '3.0001',
          withMessages: false, 
      }

      return axios.post(`${restPath}/pxy/get_homescreen_full`, params)

      .then((data) => {
          const new_user = this.state.user;
          //SH!!! remove 4 lines below
          console.log('data:');
          console.log(JSON.stringify(data));
          console.log('new_user:');
          console.log(JSON.stringify(new_user));
          new_user.is_anon = data.data.my_info.is_anon; 
          this.setState({ chatrooms: data.data.response, user: new_user});

      }).catch((err) => {
          console.error('Error in getChatrooms/get_homescreen_full : ');
          console.error(err.message);
      })
  }

    // this.state.client.getChatrooms(this.state.user, (err, chatrooms) => {
    //   console.log(`chatrooms callback: ${JSON.stringify(chatrooms)}`);
    //   //Chatrooms will be set via the getHomeScreen socket messagee
    // })
  

  disconnectEvnetHandler() {
    console.log('DISCONNECTED!');
  }

  connectEventHandler() {
    if (_.has(this.userInfo, 'name') && this.userInfo.name) {
      console.log('Connect after disconnect, facebook response found, Reconnecting...');
      if (this.state.user) {
        console.log('user found - login again');
        //Will return to homescreen.
        this.registerUser(this.userInfo);
        this.props.history.push('/');
      }
    }
  }


  handleFirstAnswerAndNewQ(chat_id, index) {
    
    const {user_id} = this.userInfo;
    const {restPath} = Config;
    const params = {
        user_id,
        access_token: this.userInfo.access_token,
        auth_provider: this.userInfo.auth_provider,
        chat_id,
        last_seen: 0
    }

    axios.post(`${restPath}/pxy/get_last_messages`, params)

    .then((res) => {

      let newChatrooms = this.state.chatrooms;
      //-1 means new question 
      if (index != -1) {
        newChatrooms.splice(index, 1);  
      }
      newChatrooms = res.data.response.concat(newChatrooms);
      this.setState({chatrooms: newChatrooms});
    })
  }

  getMissingChat(data) {

    // check if question_id with no chat_id exsist - in that case first answer to questions i asked
    const index = this.state.chatrooms.findIndex(function (element) {
      return element.question_id == data.data[1].data.question_id && !element.chat_id;
    });

    // fisrt answer to question 
    if (index != -1) {
      this.handleFirstAnswerAndNewQ(data.data[1].data.chat_id, index);
      return;
    }

    const {user_id} = this.userInfo;
    const {restPath} = Config;
    const params = {
        user_id,
        access_token: this.userInfo.access_token,
        auth_provider: this.userInfo.auth_provider,
        chat_id: data.data[1].data.chat_id,
        last_seen: 0
    }

    axios.post(`${restPath}/pxy/get_last_messages`, params)

    .then((res) => {

      //chat not in list add chat data to chatrooms
      let newChatrooms = this.state.chatrooms;
      newChatrooms = res.data.response.concat(newChatrooms);
      this.setState({chatrooms: newChatrooms});
    }).catch((err) => {
      console.error('Error getMissingChat : ');
      console.error(err.message);
    })
  }


  updateSentQuestion(chatRoom) {

    let newChatrooms = this.state.chatrooms;
    
    newChatrooms = chatRoom.concat(newChatrooms);
    this.setState({chatrooms: newChatrooms});
    
  }

  updateSuperlike(chat_id) {

    const index = this.state.chatrooms.findIndex(function (element) {
      return element.chat_id == chat_id;
    });

    if (index == -1)
      return;

    const newChatrooms = this.state.chatrooms;
    newChatrooms[index].can_send_superlike = false;

    this.setState({chatrooms: newChatrooms})
  }


  updateChatrooms(data, status) {

    const index = this.state.chatrooms.findIndex(function (element) {
      return element.chat_id == data.data[1].data.chat_id;
    });

    //remove later
    //index = -1;
    // in case of incoming message and chat not found
    if (index == -1) {
        this.getMissingChat(data);
        return;
    }

    const chatroom = [];

    chatroom[0] = this.state.chatrooms[index];
    chatroom[0].last_message_id = data.data[1].data.message_id;
    chatroom[0].last_message = data.data[1].data.text;
    chatroom[0].time_header = data.data[1].data.header_date;
    chatroom[0].unread_messages += 1;
    chatroom[0].status = status;

    let newChatrooms = this.state.chatrooms;
    newChatrooms.splice(index, 1);   
    newChatrooms = chatroom.concat(newChatrooms);
    this.setState({chatrooms: newChatrooms});

  }


  callNameEventHandler(data) {
    console.log('All events handler called, data: ');
    console.log(data);
    console.log(data.data[1]);

    if (data.data[1].callName == 'newQuestion' && data.data[1].status) {
      //data.data[1].response[0].status = 3;
      this.handleFirstAnswerAndNewQ(data.data[1].chat_id, -1);
      //const newArray = data.data[1].response.concat(this.state.chatrooms)
       //this.setState({chatrooms: newArray});
    } else if (data.data[1].callName == 'getChatHistory') {
      //Send the history to redux => will be captured by the chatroom
      this.props.dispatch({type: 'CHAT_HISTORY', chatHistory: data.data[1].data, currentChatId: data.data[1].data.chat_id});
    } else if (data.data[1].callName == 'IncomingMessage' || (data.data[1].callName == 'sendMessage' && data.data[1].response == true)
                || data.data[1].callName == 'OperatorMessage') {
      //check if superlike given if do update chatroom
      if (Object.prototype.hasOwnProperty.call(data.data[1].data, 'superLike') && data.data[1].data.superLike) {
          this.updateSuperlike(data.data[1].data.chat_id);
      }

      const status = data.data[1].callName == 'sendMessage' ? 1 : 2;
      this.updateChatrooms(data, status);

      if (data.data[1].data.chat_id == this.state.currentChatId) {
        this.props.dispatch({
          type: 'NEW_MESSAGE', 
          newMessage: data.data[1].data, 
          currentChatId: data.data[1].data.chat_id, 
          lastMessageId: data.data[1].data.message_id
        });        
      }
    } else if (data.data[1].callName == 'loginToSocket') {
        if (data.data[1].status) {
          // update user_sessions
          this.getChatrooms();
        } else {
          console.log(`Login to socket failed, result: ${JSON.stringify(data.data[1])}`);
        }
    } else if (data.data[1].callName == 'reportUser' && data.data[1].status == true) {
        this.props.dispatch({
          type: 'REPORT_STATUS', 
          status: data.data[1].status, 
        });
        this.props.history.push('/');
        this.getChatrooms();
    } else if (data.data[1].callName == 'setProfileType') {
      
      if (data.data[1].status == true) {
        //change user status and data
        const new_user = this.state.user;
        new_user.name = data.data[1].data.is_anon ? data.data[1].data.anon_name : data.data[1].data.name; 
        new_user.profile_image = data.data[1].data.is_anon ? data.data[1].data.anon_profile_image
                                   : data.data[1].data.profile_image;
        new_user.is_anon = data.data[1].data.is_anon;                                   
        this.setState({user: new_user, anonStatus: 'setProfileType Success'});
        
      } else {
        this.setState({anonStatus: data.data[1].error})
      }
  } else if (data.data[1].callName == 'changeAnonname') {
      
    if (data.data[1].status == true) {
      //change anon name only id user anon
      if (this.state.user.is_anon == true) {
      const new_user = this.state.user;
        new_user.name = data.data[1].data.anon_name; 
        this.setState({user: new_user, anonStatus: 'changeAnonname Success'});
      } else {
        this.setState({anonStatus: 'changeAnonname Success'});
      }

    }
    } else if (data.data[1].callName == 'hideUserInfo') {
      if (data.data[1].status == true) {
        
        const new_user = this.state.user;
          new_user.hide = data.data[1].hide; 
          this.setState({user: new_user});
      } 
    } else if (data.data[1].callName == 'changeImage') {
      if (data.data[1].status == true) {
        
        const new_user = this.state.user;
          new_user.profile_image = data.data[1].data.profile_image; 
          this.setState({user: new_user});
      } 
    } else if (data.data[1].callName == 'changeAnonImage') {
      if (data.data[1].status == true) {
        
        const new_user = this.state.user;
          new_user.anon_profile_image = data.data[1].data.anon_profile_image; 
          new_user.profile_image = data.data[1].data.is_anon ? data.data[1].data.anon_profile_image
                                   : data.data[1].data.profile_image;
          this.setState({user: new_user});
      } 
    } else {
      this.setState({anonStatus: data.data[1].error})
    }
  
}
  

  register(name) {
    const onRegisterResponse = user => this.setState({ isRegisterInProcess: false, user })
    this.setState({ isRegisterInProcess: true })
    this.state.client.register(name, (err, user) => {
      if (err) return onRegisterResponse(null)
      return onRegisterResponse(user)
    })
  }  


  registerUser(userInfo) {
    this.userInfo = userInfo;
    // console.log(`registerUser user: ${fbresponse.name}`)

    this.setState({isRegisterInProcess: true});
    this.setState({ user: userInfo });
    this.state.client.login(userInfo, null);
    this.props.dispatch({type: 'SET_CURRENT_USER', currentUser: {...userInfo}});
    this.setState({isRegisterInProcess: false});
    
    // this.state.client.login(userInfo, (err, user) => {

    //   console.log('login callback: ');
    //   console.log(user);
    //   console.log(err);
    //   this.setState({user});

    //   this.props.dispatch({type: 'SET_CURRENT_USER', currentUser: user});
    //   this.setState({isRegisterInProcess: false});

    //   if (err) {
    //     console.log(`register error: ${err}`);
    //     this.setState({ user: null });
    //   }

    //   //Do this only after login to socket completed
    //   //this.getChatrooms();
    // });
  }

  //SH! check for successfull/failure login?? (before getChatrooms)
  // registerUser(userInfo) {
  //   this.userInfo = userInfo;
  //   // console.log(`registerUser user: ${fbresponse.name}`)

  //   this.setState({isRegisterInProcess: true});

  //   this.state.client.login(userInfo, (err, user) => {

  //     console.log('login callback: ');
  //     console.log(user);
  //     console.log(err);
  //     this.setState({user});

  //     this.props.dispatch({type: 'SET_CURRENT_USER', currentUser: user});
  //     this.setState({isRegisterInProcess: false});

  //     if (err) {
  //       console.log(`register error: ${err}`);
  //       this.setState({ user: null });
  //     }

  //     //Do this only after login to socket completed
  //     //this.getChatrooms();
  //   });
  // }

  renderUserSelectionOrRedirect(renderUserSelection) {
    // return renderUserSelection();

    if (this.state.user) {
      return <Redirect to="/" />
    }

    return this.state.isRegisterInProcess
      ? <Loader />
      : renderUserSelection()
  }

  renderChatroomOrRedirect(chatroom, { history }, i) {
    if (!this.state.user) {
      return <Redirect to="/" />
    }

    return (
      <ErrorBoundary>
        <Chatroom
          key={`${chatroom.chat_id} _1`}
          chatroom={chatroom}
          user={this.state.user}
          onLeave={
            () => this.onLeaveChatroom(
              chatroom.chat_id,
              () => history.push('/')
            )
          }
          onUpload={
            () => {
              console.log('Inside onUpload');
              history.push(`/upload/${chatroom.chat_id}`);
            }
          }
          onSendMessage={
            (message, cb) => this.state.client.message(
              message,
              cb
            )
          }
          // registerHandler={this.state.client.registerHandler}
          // unregisterHandler={this.state.client.unregisterHandler}
          onTyping={this.state.client.typing}
          onCancelTyping={this.state.client.cancelTyping}
        />
      </ErrorBoundary>
    )
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <MainLayout
          user={this.state.user}
          info={this.userInfo}
          anonStatus={this.state.anonStatus}
          updateSentQuestion={this.updateSentQuestion}
          onSetProfileType={this.onSetProfileType}
          onChangeAnonname={this.onChaneAnonname}
          onhideUserInfo={this.onhideUserInfo}
        >
          {
            <ErrorBoundary>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={
                    props => (
                      <Home
                        user={this.state.user}
                        chatrooms={this.state.chatrooms}
                        onChangeUser={() => props.history.push(`/user?${Object.prototype.hasOwnProperty.call(this, 'props.location.search') ? this.props.location.search : ''}`)}
                        onEnterChatroom={
                          chatroomName => this.onEnterChatroom(
                            chatroomName,
                            // () => props.history.push(`/user?${Object.prototype.hasOwnProperty.call(this, 'props.location.search') ? props.location.search : ''}`),
                            // chatHistory => props.history.push({
                              () => props.history.push('/user'),
                            (/*chatHistory*/) => props.history.push({
                              pathname: chatroomName
                            })
                          )
                        }
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/user" //Change this to path="/user" if route is on click only
                  search={this.props.location.search} 
                  render={
                    (props) => {
                      console.log('Root render log');
                      const toHome = () => props.history.push('/')
                      return this.renderUserSelectionOrRedirect(() => (
                        <UserSelection
                          close={toHome}
                          register={name => this.register(name, toHome)}
                          registerUser={this.registerUser}
                          search={this.props.location.search}
                        />
                      ))
                    }
                  }
                />
                {
                  this.state.chatrooms ?
                  this.state.chatrooms.map((chatroom, i) => (
                    <Route
                      key={`${chatroom.chat_id} _2`}
                      exact
                      path={`/${chatroom.chat_id}`}
                      //toUpload={function go() { props.history.push('/upload')}}
                      render={
                        props => this.renderChatroomOrRedirect(chatroom, props, i)
                      }
                    />
                  )) : ''
                }
                {
                  this.state.chatrooms ?
                  this.state.chatrooms.map((chatroom, i) => (
                    <Route
                      key={`${chatroom.chat_id} _3`}
                      exact
                      path={`/upload/${chatroom.chat_id}`}
                      //toUpload={function go() { props.history.push('/upload')}}
                      render={
                        (props) => {
                          console.log('Image upload');
                          const onLeave = () => props.history.push(`/${chatroom.chat_id}`)
                          return (
                            <UploadImage
                              onLeave={onLeave}
                              chatroom={chatroom}
                              onSendMessage={
                                (message, cb) => this.state.client.message(
                                  message,
                                  cb
                                )
                              }
                              // register={name => this.register(name, toHome)}
                              // registerUser={this.registerUser}
                            />
                          )
                        }
                      }
                    />
                  )) : ''
                }
              </Switch>
            </ErrorBoundary>
            }
        </MainLayout>
      </MuiThemeProvider>
    )
  }
}


function mapStateToProps(state) {
  return {
    chatHistory: state.chatHistory,
    currentChatId: state.currentChatId,
    
  };
}

export default withRouter(connect(mapStateToProps)(Root));
//export default Root;
