import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

// import ReactDOM from 'react-dom';
import FacebookLoginWithButton from 'react-facebook-login';
import LoginWithApple from './LoginWithApple';
import MobileLogin from './MobileLogin';
// import Loader from './Loader';
// import axios from 'axios/index';

// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
const Config = require('Config');
// const proxyPath = Config.proxyPath;
const {
  facebookId, 
  restPath, 
  socketUrl, 
  appleSigninClient
} = Config;

class UserSelection extends React.Component {

constructor(props) {
    super(props)
    this.state = {
      //availableUsers: null,
      open: true,
    }

    this.userInfo = {};
    this.handleSelection = this.handleSelection.bind(this)
    this.loginResponse = this.loginResponse.bind(this)
    this.responseFacebook = this.responseFacebook.bind(this)
    this.responseApple = this.responseApple.bind(this)
    this.responseMobileCode = this.responseMobileCode.bind(this)
    this.verifyNewUser = this.verifyNewUser.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClickOpen = this.handleClickOpen.bind(this)
  }

  // componentDidMount() {
  //   console.log('UserSelection: ' + this.props.location.search)
  //   console.log('UserSelection: ' + this.props.search)
  //   console.log('UserSelection mounted');
  // }

  handleSelection(selectedUser) {
    this.props.register(selectedUser.name)
  }

  handleClose() {
    this.setState({ open: false });
    this.props.close();
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  responseFacebook(response) {
    // console.log('facebook response');
    // console.log(response);
    response.userID = response.id;
    // response.accessToken = response.accessToken;
    response.authProvider = 1;
    this.loginResponse(response);
  }

  responseMobileCode(response) {
    // console.log('Mobile response:');
    // console.log(response);
    if (!response.code) {
      alert('Login Failed')
      return;
    }
    // response.userID = response.userID;
    response.accessToken = response.code;
    response.authProvider = 3;
    this.loginResponse(response);
  }  

  //SH!! merge responseApple, responseMobileCode and responseFacebook
  responseApple(response) {
    // console.log('Apple response:');
    // console.log(response);
    if (!response.code) {
      alert('Login Failed')
      return;
    }
    response.userID = 'appleWebUser';
    response.accessToken = response.code;
    response.authProvider = 2;

    this.loginResponse(response);
  }  

  loginResponse(response) {
    this.userInfo = response;
    this.userInfo.userID = response.userID;
    this.userInfo.accessToken = response.accessToken;

    const user = {
      user_id: response.userID,
      access_token: response.accessToken,
      app_version: '3.0001',
      auth_provider: response.authProvider,
      device_type: 'WEB',
      device_version: 'WEB',
      iphone_number: 'WEB',
    }

    if (response.firstName) {
      user.first_name = response.firstName;
    }

    if (response.lastName) {
      user.last_name = response.lastName;
    }

    if (response.email) {
      user.email = response.email;
    }

    fetch(`${restPath}/pxy/new_user`, 
    {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        Accept: 'application/json',
      },
    })
    .then((resp) => resp.json()) //returns promise with the json object
    .then(this.verifyNewUser) 
    .catch((err) => { console.log(err) });

  }

  verifyNewUser(apiRes) {
    if (apiRes.status == true) {
      console.log('new_user success');
      console.log(apiRes);
      
      this.userInfo = apiRes.response.user_data;

      //The information here should be taken from the API
      // this.props.registerUser(this.userInfo);
      this.props.registerUser(this.userInfo);
    } else {
      console.log(`new_user error: ${JSON.stringify(apiRes)}`);
      // console.log("apiRespose error: " + apiRes);
    }
  }

  render() {
    // console.log("renderUserSelection")
    const actions = [
      <Button
        variant="contained"
        label="Cancel"
        primary
        onClick={this.props.close}
      />
    ];

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        actions={actions}
        modal="true"
      >
        <DialogTitle id="alert-dialog-title">Facebook Login</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please login with your Facebook account
            <br />
            <br />
          </DialogContentText>      
          {
            <FacebookLoginWithButton
              //appId="1665427160353481" //Live superfy account
              //appId="1598648796914684" //Test superfy account
              appId={facebookId}
              // autoLoad //Provides auto-login
              callback={this.responseFacebook}
              disableMobileRedirect
              fields="name,email,picture,id"
              //scope="user_work_history,user_posts,user_tagged_places,user_birthday,user_events,user_hometown,user_likes,user_location,user_actions.books"
              scope="public_profile"
              icon="fa-facebook" 
            />

          }                 
          {<LoginWithApple 
            clientId={appleSigninClient}
            redirectURI={socketUrl}
            scope="name email"
            callback={this.responseApple}
            //We don't use nonce for actual security at this stage,
            // it just helps to identify that the post to the homepage is from apple sign in.
            // for some reason nonce did not work
            nonce={Math.random()}
          />}

          <Accordion style={{backgroundColor: '#FFFFFF'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={{color: '#000000'}}>Login with Phone number</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MobileLogin callback={this.responseMobileCode} /> 
            </AccordionDetails>
          </Accordion>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>        
      </Dialog>
    )
  }
}

export default withRouter(UserSelection);