import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const {generateTimeLabel} = require('../both/Utils')()
const axios = require('axios');
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
const Config = require('Config');
 
const styles = {
    tooltip: {
      color: '#ffffff',
      backgroundColor: '#ffffff',
      rippleBackgroundColor: '#000000',
    },
    textField: {
          width: 200,
          color: '#fafafa',
        },
    };


class AskQuestion extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            question: '',
            questionStatus: '',
        }
      
        this.setQuestion = this.setQuestion.bind(this);
        this.sendQuestion = this.sendQuestion.bind(this);
    }

    setQuestion(event) {
        this.setState({ question: event.target.value });
    }

  sendQuestion() {

    this.setState({questionStatus: ''});

    const values = this.state.question.split(' ').filter(function (v) { return v !== '' });
    
    let sum = 0;
    values.forEach((elem) => {
       sum += elem.length;
    })

    if (sum < 13 || values.length < 3) {
        window.alert('Please enter a question larger than 13 chars and with 3 words min');
        return;
    }

    const {user_id} = this.props.info;
    const {access_token} = this.props.info;
    const {auth_provider} = this.props.info;
    const {restPath} = Config;
    const params = {
        user_id, 
        access_token,
        question: this.state.question,
        latitude: 0,
        longitude: 0,
        auth_provider,
    }

    axios.post(`${restPath}/pxy/send_question`, params)
    .then((data) => {

        this.setState({
            questionStatus: data.data.status ? 'send question success' :
                       `Error sending question: ${data.data.error}` 
        });

        const chatroom = [];
        chatroom[0] = {
          chat_direction: 1,
          image: '',
          image_type: 0,
          is_answered: false,
          my_name: null,
          privacy_state: 0,
          question: this.state.question,
          question_id: data.data.response.question_id, 
          status: 4,
          time: 0,
          time_header: generateTimeLabel((new Date().getTime()) / 1000),
          time_question: (new Date().getTime() / 1000),
        }

      this.setState({ question: ''});

      this.props.updateSentQuestion(chatroom); 

    }).catch((err) => {
         console.error('Error in send question : ');
         console.error(err.message);
         this.setState({ questionStatus: 'Fail to send question'});
    })
 
    
  }

  // updateStateChatrooms() {


  //   this.props.updateSentQuestion(sentQuestion); 
  // }


  render() {
     
    const { classes } = this.props;
    
    return (    
      <div style={{ maxWidth: 250, marginBottom: 40}}>
        <Card style={{ backgroundColor: '#E7E6E6'}}>
          <CardContent>
            {
              <div>
                <div className="pr-16" >
                  <div style={{ maxWidth: 300, marginBottom: 40}} >
                    <TextField
                      id="standard-multiline-flexible"
                      disabled={this.state.textDisabled}
                      label="Enter a Question( 13 chars and 3 words min )"
                      name="notes"
                      multiline
                      rows="3"
                      value={this.state.question}
                      className={classes.textField}
                      type="text"
                      onChange={this.setQuestion}
                    />
                  </div>    
                </div>

                <div>
                  <Button 
                    variant="contained"
                    color="secondary"
                    onClick={this.sendQuestion}
                  >
                        Send Question
                  </Button>

                  <TextField
                    id="standard-read-only-input"
                    label="Status"
                    name="status"
                    value={this.state.questionStatus}
                    className={classes.textField}
                         
                    multiline
                    rows="2"    
                  />
                </div>          
              </div>
            }
          </CardContent>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        reportStatus: state.reportStatus,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AskQuestion));
