import React from 'react';
import { Link } from 'react-router-dom'
//import styled from 'styled-components'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import ErrorBoundary from './ErrorBoundary';
import Overlay from './Overlay';
import { getQuestionsHeader, getButtonHeader, ChatWindow, ChatPanel, NoDots, InputPanel, Scrollable} from './ChatroomDivs'
import Report from './Report'
import Fab from '@material-ui/core/Fab';
import {SendTwoTone, ThumbUpTwoTone, ImageTwoTone} from '@material-ui/icons/';


const reactStringReplace = require('react-string-replace')

// const styles = theme => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     marginLeft: theme.spacing.unit,
//     marginRight: theme.spacing.unit,
//     width: 200,
//     color: '#fafafa',
//   },
//   tooltip: {
//     color: '#000000',
//     backgroundColor: '#ffffff',
//   }
// });

const styles = {
  tooltip: {
    color: '#ffffff',
    backgroundColor: '#ffffff',
    rippleBackgroundColor: '#000000',
  },
  textField: {
        // width: 200,
        color: '#fafafa',
      },
  };


class Chatroom extends React.Component {

  static newlinefy(key, text) {
    let j = 1; //Even br needs unique key.
    return reactStringReplace(text, '\n', () => (<br key={`${key}_${++j}br`} />));
    // return reactStringReplace(text, '\n', (match, i) => (<br key={`${key}_${++j}br`} />));
  }

  static urlify(key, text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let j = 1; //for some reason using i didn't work;
    return reactStringReplace(text, urlRegex, 
      match => (<Link target="_blank" style={{ color: '#fafafa' }} key={`${key}_${++j}_l`} to={match}>{match}</Link>));
      // (match, i) => (<Link target="_blank" style={{ color: '#fafafa' }} key={`${key}_${++j}_l`} to={match}>{match}</Link>));
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      input: '',
    }

    this.typingTimerId = 0;
    this.onTyping = this.onTyping.bind(this)
    this.onCancelTyping = this.onCancelTyping.bind(this)
    this.onInput = this.onInput.bind(this)
    this.onSendMessage = this.onSendMessage.bind(this)
    this.onSendSuperlike = this.onSendSuperlike.bind(this)
    // this.onMessageReceived = this.onMessageReceived.bind(this)
    this.updateChatHistory = this.updateChatHistory.bind(this)
    this.scrollChatToBottom = this.scrollChatToBottom.bind(this)
    // this.newlinefy = this.newlinefy.bind(this)
    // this.urlify = this.urlify.bind(this)
  }

  componentDidMount() {
    //this.props.registerHandler(this.onMessageReceived)
    this.scrollChatToBottom()
  }

  componentDidUpdate() {
    this.scrollChatToBottom()
  }

  componentWillUnmount() {
    // this.props.unregisterHandler()
  }

  onInput(e) {
    this.setState({
      input: e.target.value
    })
  }

  onSendSuperlike() {

    if (!this.props.chatroom.can_send_superlike) {
        return;      
    }

    this.props.onSendMessage({
      callName: 'sendMessage', chat_id: this.props.chatroom.chat_id.toString(), superLike: 1, text: 'superLike was given'
     }, (err) => {
      if (err) {
         console.error(err)
      }
    })
  }


  onSendMessage() {
    console.log('onSendMessage');
    if (!this.state.input)
      return

      // this.props.onSendMessage(this.state.input, (err) => {
    this.props.onSendMessage({ callName: 'sendMessage', chat_id: this.props.chatroom.chat_id.toString(), text: this.state.input }, (err) => {
        if (err) {
          return console.error(err)
        }
        return this.setState({ input: '' })
    })
  }

  onTyping() {
    if (this.typingTimerId != 0) {
      clearTimeout(this.typingTimerId);
      this.typingTimerId = setTimeout(this.onCancelTyping, 3000);
      return;
    }

    this.typingTimerId = setTimeout(this.onCancelTyping, 3000);
    this.props.onTyping();
  }

  onCancelTyping() {
    this.typingTimerId = 0;
    this.props.onCancelTyping();
  }

  // onMessageReceived(entry) {
  //   console.log('onMessageReceived:', entry)
  //   this.updateChatHistory(entry)
  // }

  updateChatHistory(entry) {
    this.setState({ chatHistory: this.state.chatHistory.concat(entry) })
  }

  scrollChatToBottom() {
    const element = document.getElementById("scrollableID");
    if (element)
      element.scrollTop = element.scrollHeight;


    // if (this.panel)
    //   this.panel.scrollTo(0, this.panel.scrollHeight)
  }


  render() {
    const { classes } = this.props;
    return (
      <ErrorBoundary>
        <div style={{ height: '100%' }}>

          <div style={{ maxWidth: '420px'}} >
            <Accordion style={{backgroundColor: '#E7E6E6' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{color: '#FF0000'}}>Click to Report a User</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Report chat_id={this.props.chatroom.chat_id.toString()} sendMessage={this.props.onSendMessage} userId={this.props.user.id} authProvider={this.props.user.auth_provider} accessToken={this.props.user.access_token} otherUserId = {this.props.chatroom.other_user_info_array.other_user_id} />
              </AccordionDetails>
            </Accordion>
          </div>
          {getButtonHeader(this.props, classes.button)}
          {getQuestionsHeader(this.props)}

          <ChatWindow>                        
            <ChatPanel>
              <Scrollable id="scrollableID" innerRef={(panel) => { this.panel = panel; }}>
                <List>
                  {
                  this.props.chatHistory && this.props.chatHistory.map ?
                  this.props.chatHistory.map(
                    row => [
                      <NoDots key={row.message_id}>
                        {row.header_date 
                          ? <ListSubheader><Typography style={{ color: '#aaaaaa' }}>{row.header_date}</Typography></ListSubheader>
                          : ''}
                        <ListItem
                          key={`${row.message_id}_0`}
                          style={{ color: '#fafafa' }}
                        >
                          <Avatar 
                            key={`${row.message_id}_1`} 
                            src={row.incoming == 1 
                            ? this.props.chatroom.other_user_info_array.profile_image 
                            : this.props.currentUser.profile_image}
                            classes={classes} 
                          />
                          <ListItemText 
                            primary={
                              <Typography style={{ color: '#fafafa' }}>
                                {`${row.incoming == 1 
                                  ? this.props.chatroom.other_user_info_array.name 
                                  : this.props.chatroom.my_name == '' 
                                  //Has to be taken from the chat for cases where current user in anon in this chat.
                                    ? 'me' 
                                    : this.props.chatroom.my_name}`}
                              </Typography>
                            } 
                            secondary={
                            row.text ? 
                              <Typography style={{ color: '#fafafa', display: 'inline' }}> 
                                {Chatroom.urlify(row.message_id, Chatroom.newlinefy(row.message_id, row.text))} 
                              </Typography>
                            : ''
                          } 
                          />
                        </ListItem>

                        {row.image ? <ListItem><img alt="" src={row.image} width="100%" onLoad={this.scrollChatToBottom} /></ListItem> : ''}

                      </NoDots>,
                      <Divider key={`${row.message_id}_3`} />
                    ]
                  )
                  : 
                  ''
                }
                </List>
              </Scrollable>
              <InputPanel style={{ width:'100%'}}>
                <TextField
                  style={{ width:'100%'}}
                  placeholder="Enter a message."
                  label={<Typography style={{ color: '#fafafa' }}>Enter a message.</Typography>}
                  multiline
                  rows={2}
                  rowsMax={4}
                  onChange={this.onInput}
                  value={this.state.input}
                  onKeyPress={e => (e.key === 'Enter' && e.shiftKey ? null : e.key === 'Enter' ? this.onSendMessage() : this.onTyping())}
                  className={classes.textField}
                  InputProps={{
                    classes: { 
                      input: classes.textField, 
                      root: classes.textField,                                        
                    }
                  }}
                />
              </InputPanel>
              <div align='right'>
                <Tooltip id="tooltip-send" title="Send Message" placement="top">
                <Fab 
                  size="small" 
                  color="primary" 
                  aria-label="send" 
                  className={classes.margin}
                  onClick={this.onSendMessage}
                >
                  <SendTwoTone />
                </Fab>
                </Tooltip>
                <Tooltip
                  id="tooltip-superlike"
                  title={this.props.chatroom.can_send_superlike ? 'Send superlike' : 'superlike already given'}
                  placement="top"
                >
                <Fab 
                  size="small" 
                  color="primary" 
                  aria-label="superlike" 
                  className={classes.margin}
                  onClick={this.onSendSuperlike}
                >
                  <ThumbUpTwoTone />
                </Fab>
                </Tooltip>
                <Tooltip id="tooltip-upload" title="Upload Image" placement="top">
                <Fab 
                  size="small" 
                  color="primary" 
                  aria-label="upload" 
                  className={classes.margin}
                  onClick={this.props.onUpload}
                >
                  <ImageTwoTone />
                </Fab>
                </Tooltip>
                </div>
            </ChatPanel>
            <Overlay
              opacity={0.6}
              background="#111111"
            />
          </ChatWindow>
        </div>
      </ErrorBoundary>
    )
  }
}

//In case where every chat history will be saved under different section,
//  the function below should take only the section for the relevant chat.
function mapStateToProps(state, ownProps) {
  console.log('Chatroom mapStateToProps:');
  console.log(state);
  console.log(ownProps)

  if (state.newMessage && state.currentChatId == ownProps.chatroom.chat_id) {
    if (state.chatHistory)
      state.chatHistory.push(state.newMessage);
  }

  state.newMessage = null;

  return {
    chatHistory: state.chatHistory,
    currentChatId: state.currentChatId,
    currentUser: state.currentUser,
    //For some reason, just pushing the new message to the history is not detected by react.
    //  therefore, the chat room is not re-rendered.
    //  putting the last message here is for making sure that the change will be detected and chatroom re-rendered.
    lastMessageId: state.lastMessageId ? state.lastMessageId : 0, 
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Chatroom));

//export default Chatroom;
