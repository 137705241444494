import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { connect } from 'react-redux';
//import Overlay from './Overlay';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    display: 'flex',
    background: 'rgba(0,0,0,.3)',
    //background: '#000000',
    //backgroundColor: theme.palette.background.paper,
  },
});


const options = [
  {text: 'All', filter: (chatroom) => { return true }},
  {text: 'Unanswered questions', filter: (chatroom) => { return chatroom.chat_direction == -1 && chatroom.is_unanswered }},
  {text: 'Unanswered Unread questions', filter: (chatroom) => { return chatroom.chat_direction == -1 && chatroom.is_unanswered && chatroom.status == 3 }},
  {text: 'Unread', filter: (chatroom) => { return chatroom && chatroom.unread_messages && chatroom.unread_messages > 0 }},
  //The return should be in one line, otherwise firefox issues false warning on unreachable code.
  {text: 'Unread questions', filter: (chatroom) => { return chatroom && chatroom.unread_messages && chatroom.unread_messages > 0 && chatroom.status && chatroom.status == 3 }},
];

class SelectionMenu extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleClickListItem = this.handleClickListItem.bind(this)
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
        
    this.button = null;

    this.state = {
      anchorEl: null,
      selectedIndex: 0,
    };
  }

  handleClickListItem(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuItemClick(event, index) {
    this.setState({ selectedIndex: index, anchorEl: null });
    this.props.dispatch({type: 'CHAT_FILTER', chatFilter: options[index].filter, lastFilterTime: new Date()});
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.root}>
        <List component="nav" style={{display: 'table-cell'}} >
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Filter Chat Type"
            onClick={this.handleClickListItem}
          >
            <ListItemText
            //primary = "Filter Chat Type"
              primary={<Typography style={{color: '#ffffff', display: 'inline-block'}}> {'Filter Chat Type'} </Typography>}
            //secondary={options[this.state.selectedIndex].text}
              secondary={<Typography style={{color: '#909090', textAlign: 'center'}}> {options[this.state.selectedIndex].text} </Typography>}
            />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option.text}
              //disabled={index === 0}
              selected={index === this.state.selectedIndex}
              onClick={event => this.handleMenuItemClick(event, index)}
            >
              {option.text}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

SelectionMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect()(SelectionMenu));
