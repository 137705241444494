import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import styled from 'styled-components'
// import Fab from '@material-ui/core/Fab';
// import EditIcon from '@material-ui/icons/Edit';

export const ChatWindow = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 420px;
  box-sizing: border-box;
`
export const ChatPanel = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  color: #fafafa !important;
  border-bottom: 1px solid;
  overflow: scroll;
  line-height: 1.2em;
  max-height: 12em;
  padding: 0 2%;
  text-align: center;
`

export const ButtonHeader = styled.div`
  display: flex;
  margin-left:auto;
  margin-right:0px;
  z-index: 1;
  color: #fafafa !important;
  line-height: 3em;
  height: 3em;
  float: right;
 `

// export const ReportButtonHeader = styled.div`
//   display: flex;
//   margin-left:auto;
//   margin-right:0px;
//   z-index: 1;
//   color: #fafafa !important;
//   line-height: 3em;
//   height: 3em;
//   float: right;
//   `

export const Title = styled.p`
  text-align: center;
  font-size: 1.1em;
  word-wrap: wrap;
  word-break: break-word;
`

export const NoDots = styled.div`
  hr {
    visibility: hidden;
  }
`

export const InputPanel = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  align-self: center;
  border-top: 1px solid #fafafa;
`

export const ChatroomImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
`

export const Scrollable = styled.div`
  height: 100%;
  overflow: auto;
`


export const getButtonHeader = (props, className) => {
  return (

    <Button
    variant="contained"
    onClick={props.onLeave}
    color="secondary"
    // className={classes.button}
    className={className}
    style={{ width: 20 }}
    endIcon={<Icon>close</Icon>}
    >
      {/* Send */}
    </Button>
  )


    // <ButtonHeader>
    //   <Button variant="raised" onClick={props.onLeave}>
    //     <Icon
    //       style={{ fontSize: 20 }}
    //       className="material-icons"
    //     >
    //       {'close'}
    //     </Icon>
    //   </Button>
    // </ButtonHeader>);
}


// export const getReportButtonHeader = (props) => {
//   return (
//     <ReportButtonHeader>
//       <Button variant="raised" onClick={props.onLeave}>
//         <Icon
//           style={{ fontSize: 20 }}
//           className="material-icons"
//         >
//           {'report'}
//         </Icon>
//       </Button>
//     </ReportButtonHeader>);
// }

export const getQuestionsHeader = (props) => {
  return (
    <Header>
      <Title>
        { props.chatroom.question }
      </Title>
    </Header>
  ); 
}
